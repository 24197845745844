<script>
  import { _ } from "svelte-i18n";
  import { startup_state, keywords_store, loading_keywords } from "$lib/store.js";
  import { onMount } from "svelte";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { goto } from "$app/navigation";
  // import { spring } from "svelte/motion";

  let keywordsArray = Array.from({ length: 22 }, (_, i) => `mood${i + 1}`);

  function changeStartupState() {
    if ($startup_state) {
      startup_state.update(() => {
        return false;
      });
    }
  }

  async function getKeywords(prompt) {
    changeStartupState();
    $loading_keywords = true;
    const endpoint = "/api/search/getTagsFromPrompt"; //GET
    const url = `${PUBLIC_API_BASE_URL}/${endpoint}/${encodeURIComponent($_(prompt))}`;
    let new_keywords = [];
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch keywords");
      }
      const response_json = await response.json();
      new_keywords = response_json.data.tags; // Assume this returns an array of objects
    } catch (err) {
      console.error(err.message);
      goto("/error");
    } finally {
      updateKeywords(new_keywords);
    }
    $loading_keywords = false;
  }

  function updateKeywords(new_keywords) {
    keywords_store.update((keywords) => {
      // Map guarantees unique entries and acts as a set: (JS has no comaparator for Set)
      const keywordsMap = new Map(keywords.map((keyword) => [keyword.displayText, keyword]));
      // add new keywords, overwrite existing ones:
      new_keywords.forEach((k) => {
        keywordsMap.set(k.displayText, k);
      });
      return Array.from(keywordsMap.values());
    });
  }

  // let initialHeight;
  let isMoodsExpanded = false;
  let keywordInspirations;
  let moods;
  let searchElements;
  // let moodButton;
  let claim;

  const toggleMoods = () => {
    isMoodsExpanded = !isMoodsExpanded;
    // moods.style.height = `${moods.scrollHeight}px`;
    // keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
    claim.style.height = `${claim.scrollHeight}px`;

    if (isMoodsExpanded) {
      moods.style.height = `${moods.scrollHeight}px`;
      moods.style.paddingBottom = "22px";
      moods.style.paddingTop = "22px";
      keywordInspirations.style.height = `${moods.scrollHeight + 38 + claim.scrollHeight + 44}px`;
      searchElements.style.height = `${moods.scrollHeight + 38 + claim.scrollHeight + 44}px`;
      setTimeout(() => {
        moods.style.height = `auto`;
        searchElements.style.height = `auto`;
        claim.style.height = `auto`;
      }, 250);
    } else {
      // Collapse moods and update searchElements height
      moods.style.height = "0";
      moods.style.paddingBottom = "0px";
      moods.style.paddingTop = "0px";
      keywordInspirations.style.height = `${38 + claim.scrollHeight}px`;
      // searchElements.style.height = `${searchElements.scrollHeight - moods.clientHeight - 44}px`;
      setTimeout(() => {
        searchElements.style.height = `auto`;
        // keywordInspirations.style.height = `auto`;
        claim.style.height = `auto`;
      }, 250);
    }
  };

  const adjustMoodsHeight = () => {
    searchElements.style.height = `${searchElements.scrollHeight}px`;

    if (moods.style.height > 0) {
      moods.style.height = `auto`;
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
    }
    keywordInspirations.style.height = `auto`;
    searchElements.style.height = `auto`;
  };

  const updatekeywordInspirationsProperties = () => {
    const scrollY = window.scrollY;

    // If scrolled down, set height to 0; if at the top, set it to its full content height
    const contentBoxRect = searchElements.getBoundingClientRect(); // Get contentBox position relative to the viewport

    if (scrollY > 0 && contentBoxRect.bottom <= window.innerHeight) {
      keywordInspirations.style.height = `0px`;
      keywordInspirations.style.top = `-${keywordInspirations.scrollHeight}px`;
    } else {
      keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
      keywordInspirations.style.top = `0px`;
      // setTimeout(() => {
      //   keywordInspirations.style.height = `auto`;
      // }, 250);
    }

    keywordInspirations.style.position = "relative";
  };

  onMount(() => {
    searchElements = document.querySelector("#search-elements");
    keywordInspirations = document.querySelector("#keyword-inspirations");
    moods = document.querySelector("#moods");
    claim = document.querySelector("#claim");
    // keywordInspirations.style.height = `auto`;
    keywordInspirations.style.height = `${keywordInspirations.scrollHeight}px`;
    keywordInspirations.style.top = `0px`;
    claim.style.height = `auto`;

    window.addEventListener("scroll", updatekeywordInspirationsProperties);
    window.addEventListener("resize", adjustMoodsHeight);

    return () => {
      window.removeEventListener("scroll", updatekeywordInspirationsProperties);
      window.removeEventListener("resize", adjustMoodsHeight);
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="keyword-inspirations">
  <div id="moods">
    {#each keywordsArray as keyword}
      <div
        role="button"
        tabindex="-1"
        class="inspiration-keyword"
        class:included={false}
        on:click={(e) => {
          getKeywords(keyword);
          e.currentTarget.classList.toggle("included");
        }}
        on:keypress={() => getKeywords(keyword)}>
        {$_(keyword) ?? keyword}
      </div>
    {/each}
  </div>
  <button id="mood-button" class="inspiration-keyword-button" on:click={toggleMoods}>
    {isMoodsExpanded ? $_("hide_mood_inspirations") : $_("show_mood_inspirations")}
  </button>
  {#if $startup_state}
    <div id="claim"><h1>{$_("claim")}</h1></div>
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  #keyword-inspirations {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-top: -1px;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 0px;
    transition: all 0.25s ease-in-out;
  }

  #moods {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    overflow: hidden;
    height: 0;
    background: var(--white-10);
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 4px;
    padding-right: 4px;
    /* border-bottom: 1px solid var(--white-30); */
    transition: all 0.25s ease-in-out;
    border-radius: 0 0 24px 24px;
  }

  .inspiration-keyword-button {
    cursor: pointer;
    display: block;
    cursor: pointer;
    color: var(--white-50);
    padding-left: 30px;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0 0 12px 12px;
    font-size: 16px;
    /* margin: 2px 3px; */
    height: 38px;
    text-align: center;
    word-wrap: break-word;
    inline-size: max-content;
    hyphens: auto;
    border: 1px solid transparent;
    background: var(--white-10);
    background-image: url("/icons/icon_mood.svg");
    background-repeat: no-repeat;
    background-position: 8px center;
    background-size: 16px 15px;
  }
  #claim {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  h1 {
    font-weight: 600;
    color: var(--brightest-red);
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: uppercase;
  }
  .inspiration-keyword {
    /* z-index: 2; */
    display: block;
    cursor: pointer;
    color: var(--solid-grey);
    padding: 0.5rem 1rem;
    border-radius: 12px;
    font-size: 16px;
    height: 20px;
    margin: 2px 3px;
    text-align: center;
    word-wrap: break-word;
    max-width: 9rem;
    inline-size: max-content;
    hyphens: auto;
    border: 1px solid transparent;
    background: var(--white-90);
    color: var(--solid-grey);
  }

  .included {
    color: var(--white);
    background-color: var(--solid-blue);
  }

  .inspiration-keyword:hover {
    cursor: pointer;
    background-color: var(--solid-blue);
    color: var(--white);
    border: 1px solid var(--white);
  }

  /* @media (max-width: 480px) {
    .inspiration-keyword {
      margin-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
      white-space: nowrap;
    } 
  }*/
</style>
